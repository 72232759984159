import $ from 'jquery';
import CaptchaComponent from './captcha.component';

export default class PasswordComponent {
    constructor() {
        this.captchaComponent = new CaptchaComponent();
    }

    events() {
        $('body')
            .on('click.toggle', '.btn-toggle-password-visibility', this.handlerClickToggle.bind(this))
            .on('click.generate', '.btn-generate-password', this.handlerClickGenerate.bind(this))
        ;
    }

    handlerClickToggle(event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        let $self = $(event.currentTarget);
        let $glyphicon = $self.find('.glyphicon');
        let type = 'text';
        let text = i18next.t('Скрыть пароль');
        if ($glyphicon.hasClass('glyphicon-eye-close')) {
            type = 'password';
            text = i18next.t('Показать пароль');
        }
        $self.closest('.input-group').find('input').attr('type', type);
        $self.attr({
            'aria-label': text,
            title: text
        });
        $glyphicon.toggleClass('glyphicon-eye-open glyphicon-eye-close');
    }

    handlerClickGenerate(event) {
        let $self = $(event.currentTarget);
        $self.prop('disabled', true);
        $.post('/user/?m=_generatePassword', data => {
            $self.closest('form').find('.btn-toggle-password-visibility').each((index, element) => {
                let $self = $(element);
                if ($self.data('ignoreGeneratePassword')) {
                    return;
                }
                if ($self.find('.glyphicon').hasClass('glyphicon-eye-open')) {
                    $self.trigger('click.toggle');
                }
                $self.closest('.input-group').find('input').val(data).trigger('input').trigger('blur');
            });
        }).fail(jqXHR => {
            console.error(jqXHR);
            if (429 === jqXHR.status) {
                this.captchaComponent.showModal(
                    'generate_password',
                    () => $('.btn-generate-password').trigger('click.generate')
                );
            }
        }).always(() => {
            $self.prop('disabled', false);
        });
    }
}
