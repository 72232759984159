;(function ($) {
    $.noty.layouts.topRight = {
        name: 'topRight',
        options: {},
        container: {
            object: '<ul id="noty_topRight_layout_container" />',
            selector: 'ul#noty_topRight_layout_container',
            style: function () {
				var top = 40;
				try {
					/* 166982 и.9108
                     * Регулировка верхнего отступа всплывающего уведомления в зависимости от высоты блока верхнего меню
                     */
					var $topMenu = $('.topMenu0');
					var minHeightMenu = parseInt($topMenu.css('min-height'));
					if (!isNaN(minHeightMenu) && $topMenu.height() > minHeightMenu) {
						top = $topMenu.height() - 2;
					}
				} catch (e) {}
                $(this).css({
                    top: top,
                    right: 8,
                    textAlign: 'center',
                    position: 'fixed',
                    width: '310px',
                    height: 'auto',
                    margin: 0,
                    padding: 0,
                    listStyleType: 'none',
                    zIndex: 10000000
                });
                if ($.browser.msie && $.browser.version < 9) {
                    $(this).css({
                        padding: '8px',
                        backgroundColor: '#DDEDFF'
                    });
                }
                if (window.innerWidth < 600) {
                    $(this).css({
                        right: 5
                    });
                }
            }
        },
        parent: {
            object: '<li />',
            selector: 'li',
            css: {}
        },
        css: {
            display: 'none',
            width: '310px'
        },
        addClass: ''
    };
})(jQuery);