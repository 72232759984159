$(document).ready(function () {
    function get_cookie(cookie_name) {
        var results = document.cookie.match('(^|;) ?' + cookie_name + '=([^;]*)(;|$)');
        if (results)
            return (unescape(results[2]));
        else
            return null;
    }

    function remove_the_border_ie8() {
        if ((typeof $.browser.msie === 'boolean') && ($.browser.msie) && ($.browser.version == '8.0')) {
            $('#noty_topRight_layout_container li').css('border', 'none');
        }
    }

    /* P: 3490 H: 106962 an.gerasimov 06.12.15 */
    function NotyGenerate(layout, _error) {
        var text = $('#errorEmailOrPassword').html();
        var classMsg = '';
        if (!navigator.cookieEnabled) {
            text = '<strong>' + i18next.t('В Вашем браузере отключены куки. Для правильной работы сайта, пожалуйста')
                + ', <a target="_blank" rel="noopener noreferrer" href="//help.yandex.ru/common/browsers-settings/browsers-cookies.xml">'
                + i18next.t('включите их') + '</a>.</strong>';
            classMsg = 'justify_message';
        } else if ($.cookie('session_end') == 1) {
            text = '<strong>' + i18next.t('Ваша сессия истекла. Пожалуйста, авторизуйтесь заново.') + '</strong>';
            classMsg = 'justify_message';
        } else if (_error == 2) {
            /* 173956 и.9942
             * Информирование сотрудников, чьи учетные записи отключены, c целью исключения обращений в ТП по проблемам авторизации
             */
            let login = String($('#log_u').val());
            if (login.indexOf('@autotrade.su') != -1 || login.indexOf('@pavelauto.parts') != -1) { /*206366 и.12183*/
                text = '<strong>' + i18next.t('Ваша учетная запись отключена. Для включения учетной записи обратитесь к Вашему руководителю.') + '</strong>';
            } else {
                /* 166982 и.9108
                * Обновление сообщения для пользователей, чьи учетные записи отключены, где добавлена ссылка на розничный сайт
                */
                if (i18next.language === 'en') { /*206366 и.12183*/
                    text = '<div class="text-right"><strong>' + i18next.t('Ваша учетная запись отключена.') + ' '
                        + i18next.t('Если Вы желаете продолжить сотрудничество в качестве оптового или корпоративного клиента')
                        + ', <a id="contact" class="dotted-blue-link" href="javascript:void(0);" style="line-height: 10px;">' + i18next.t('свяжитесь с нами') + '</a>.' +
                        '</strong></div>';
                } else {
                    text = '<div class="text-right"><strong>'
                        + i18next.t('Узнать цены и наличие товара Вы всегда можете на нашем сайте')
                        + ' <a target="_blank" href="//autotrade.su">autotrade.su</a>.<br><br>'
                        + i18next.t('Если Вы желаете продолжить сотрудничество в качестве оптового или корпоративного клиента')
                        + ', <a id="contact" class="dotted-blue-link" href="javascript:void(0);" style="line-height: 10px;">' + i18next.t('свяжитесь с нами') + '</a>.</strong></div>';
                }
            }
        } else if (5 === _error) {
            text = `<strong>${i18next.t('Введите код с картинки в окне &laquo;Авторизация не удалась&raquo; и повторите попытку.')}</strong>`;
        }
        var n = noty({
            text: text, //н56755
            layout: layout,
            // theme: 'defaults',
            theme: 'defaultTheme', // H: 113623
            type: 'alert',
            dismissQueue: true, // If you want to use queue feature set this true
            template: '<div class="noty_message ' + classMsg + '"><span class="noty_text"></span><div class="noty_close"></div></div>',
            animation: {
                open: {
                    height: 'toggle'
                },
                close: {
                    height: 'toggle'
                },
                easing: 'swing',
                speed: 100 // opening & closing animation speed
            },
            timeout: false, // delay for closing event. Set false for sticky notifications
            force: false, // adds notification to the beginning of queue when set to true
            modal: false,
            closeWith: [(_error == 2 ? 'button' : 'click')], /*166982 и.9108*/ // ['click', 'button', 'hover']
            callback: {
                onShow: function () { /*166982 и.9108*/
                    var $notyContainer = $('#noty_topRight_layout_container');

                    // Обработчик
                    $notyContainer.delegate('#contact', 'click', function () {
                        $.noty.closeAll();
                        showManagersContacts();
                    });
                    // Механизм сокрытия, т.к. при closeWith, равным button, блок автоматически не скрывается при клике по нему
                    $notyContainer.find('*').unbind('click').bind('click', function (e) {
                        if (e.target.tagName == 'A') return;
                        $.noty.closeAll();
                    });
                }
            }
        });
    }

    /* 166982 и.9108
     * Показ модального окна с контактными данными менеджеров\сотрудников, ответственных за активацию учетных записей пользователей
     */
    function showManagersContacts() {
        // Генерация окна
        $('body').append(
            '<div class="modal fade" id="contacts-modal" style="z-index: 1070;">' +
            '   <div class="modal-dialog">' +
            '       <div class="modal-content">' +
            '           <div class="modal-header">' +
            '               <button type="button" class="close" data-dismiss="modal">&times;</button>' +
            '               <h4 class="modal-title">' + i18next.t('Контактная информация по ближайшему к Вам филиалу') + '</h4>' +
            '           </div>' +
            '           <div class="modal-body text-center">' +
            '               <p style="margin: 10px 0;">' +
            '                   <span id="search-loader" class="g-png24" style="left: 0; margin: 0 auto;">' + i18next.t('Идет загрузка') + '...</span>' +
            '               </p>' +
            '           </div>' +
            '           <div class="modal-footer">' +
            '               <button type="button" class="btn btn-primary" data-dismiss="modal"><i class="glyphicon glyphicon-remove"></i> ' + i18next.t('Закрыть') + '</button>' +
            '           </div>' +
            '       </div>' +
            '   </div>' +
            '</div>'
        );

        // Настройки показа и сокрытия окна
        var $modal = $('#contacts-modal');
        $modal
            .on('shown.bs.modal', function () {
                // Центрирование окна при показе и изменении размеров окна браузера
                modal_centered($modal);
                $(window).on('resize.contacts-modal', function () {
                    modal_centered($modal);
                });

                // Получение контаткных данных
                var u = $('#log_u').val();
                var p = $('#log_p ').val();
                if (u.length === 0 || p.length === 0) {
                    NotyGenerate('topRight', 3);
                } else {
                    getManagersContacts(u, p);
                }
            })
            .on('hidden.bs.modal', function () {
                // Удаление окна после сокрытия
                $modal.remove();
            });

        // Ограничение ширины и высоты окна
        var minWidth = 1000;
        $modal.find('.modal-dialog').css({'min-width': minWidth + 'px', 'margin-top': '30px'});

        // Показ окна
        $modal.modal({backdrop: 'static'});
        $modal.modal('show');
    }

    /* 166982 и.9108
     * Получение и показ контактных данных менеджеров\сотрудников, ответственных за активацию учетных записей пользователей
     */
    function getManagersContacts(u, p) {
        $.post('/login/?m=getManagersContacts', {'u': u, 'p': p}, function (data) {
            var $modal = $('#contacts-modal');

            // Вывод информации
            $modal.find('.modal-body').html(data);

            // Центрирование окна
            modal_centered($modal);
        });
    }

    if (!navigator.cookieEnabled) {
        NotyGenerate('topRight');
        remove_the_border_ie8();
    }

    if (get_cookie('session_end') == 1) {
        NotyGenerate('topRight');
        remove_the_border_ie8();
    }

    var $logU = $('#log_u');
    var $logP = $('#log_p');
    var $LinklogIn = $("#linkLogIn");

    $('body')
        .delegate('#log_u, #log_p', 'input', function () { /*166982 и.9108*/
            if ('log_u' === this.id) {
                this.value = this.value.replace(/\s/g, '');
            }
            if ($('#noty_topRight_layout_container').length > 0) {
                $('#noty_topRight_layout_container').remove();
            }
        });

    $logU.keypress(function (e) {
        if (e.which == 13 && $logU.val() != '' && $logP.val() != '') {
            $LinklogIn.click();
            e.preventDefault();
        }
    });
    $logP.keypress(function (e) {
        if (e.which == 13) {
            $LinklogIn.click();
            e.preventDefault();
        }
    });

    $LinklogIn.click(function (event) {
        var time = event.timeStamp;

        /* P: 3811 H: 106628 an.gerasimov 21.11.15 */
        if ($('#login').val().length && $('#password').val().length) {
            $logP.val('');
            NotyGenerate('topRight');
            remove_the_border_ie8();
        }

        if ($logP.val().length < 3) {
            if ($('#noty_topRight_layout_container').length > 0) {
                $('#noty_topRight_layout_container').remove();
            }
            $logP.val('');
            NotyGenerate('topRight');
            remove_the_border_ie8();
        } else {
            $LinklogIn.attr('disabled', true); /*166982 и.9108*/
            $.ajaxSettings.cache = false;

            var params = {
                'login': $logU.val(),
                'pass': $logP.val(),
                'remember': $('#iRemember').attr('checked')
            };
            $.post('/login/?time=' + time + '&m=entrance', params, function (data) {
                if (parseInt(data) >= 5) { /* P: 3811 H: 106628 an.gerasimov 21.11.15 */
                    if ($('#noty_topRight_layout_container').length > 0) {
                        $('#noty_topRight_layout_container').remove();
                    }
                    $logP.val('');
                    NotyGenerate('topRight', 5);
                    remove_the_border_ie8();
                    bruteforce(parseInt(data) - 2);
                } else if (parseInt(data) == 2) { /* P: 3490 H: 106962 an.gerasimov 06.12.15 */
                    if ($('#noty_topRight_layout_container').length > 0) {
                        $('#noty_topRight_layout_container').remove();
                    }
                    /* 166982 и.9108
                     * Отмена очищения поля с паролем, во-первых, для возмржности использования логина и пароля для идентификации
                     * пользователя, для которого необходимо показать контаткы сотрудников для связи, во-вторых, нет необходимости,
                     * ведь пользователь указал пароь верно
                     */
                    //$logP.val('');
                    NotyGenerate('topRight', 2);
                    remove_the_border_ie8();
                } else if (data == false || data > 2) { /* P: 4129 H: 108579 an.gerasimov 27.02.16 */
                    if ($('#noty_topRight_layout_container').length > 0) {
                        $('#noty_topRight_layout_container').remove();
                    }
                    $logP.val('');
                    NotyGenerate('topRight');
                    remove_the_border_ie8();
                } else {
                    var path = !get_cookie('first_page') ? '/' : get_cookie('first_page');
                    /* Заставим браузер выдавать сообщение о сохранении пароля:
                     * - Меняем тип кнонки button на submit
                     * - Подменяем action формы, тем самым "провоцируя" браузер
                     * - Выполняем клик и тем самым перенаправляем (вместо location)
                     */
                    $('form#formLF').attr('action', path);
                    $LinklogIn.parent().find('input').click();
                }
            }).always(function () { /*166982 и.9108*/
                $LinklogIn.attr('disabled', false);
            });
        }
    });
});

/* P: 3811 H: 106628 an.gerasimov 21.11.15 */
function bruteforce(counter) {
    var html_code,
        iamhuman;

    html_code = '<div class="modal fade" id="iamhuman">\n' +
        '	<div class="modal-dialog">\n' +
        '		<div class="modal-content">\n' +
        '			<div class="modal-header">\n' +
        '				<h4 class="modal-title">' + i18next.t('Авторизация не удалась') + '</h4>\n' +
        '			</div>\n' +
        '			<div class="modal-body">\n' +
        '				<p class="text-justify">\n' +
        '                   ' + i18next.t('Уважаемый клиент! Мы обнаружили, что Вам не удалось авторизоваться после') +
        ' <nobr>' + counter + ' ' + i18next.t('попыток') + '</nobr> ' + i18next.t('ввода') + ' e-mail\\' + i18next.t('пароля') + '. ' +
        '                   ' + i18next.t('Пожалуйста, проверьте правильность написания вводимых данных.') + '\n' +
        '                   <br /><br />\n' +
        '                   <ul>\n' +
        '                       <li>' + i18next.t('Возможно, нажата клавиша') + ' <strong>Caps Lock</strong>?</li>\n' +
        '                       <li>' + i18next.t('Может быть, у Вас включена неправильная') + ' <strong>' + i18next.t('раскладка') + '</strong> (' + i18next.t('русская или английская') + ')?</li>\n' +
        '                       <li>' + i18next.t('Попробуйте набрать свой пароль в текстовом редакторе и') + ' <strong>' + i18next.t('скопировать') + '</strong> ' +
        i18next.t('в поле &laquo;Пароль&raquo;') + '.</li>\n' +
        '                   </ul>\n' +
        '                   <br />\n' +
        '                   ' + i18next.t('Если Вы всё внимательно проверили, но войти всё равно не удается, Вы можете') +
        ' <a class="link_at" href="/login/?m=forgot">' + i18next.t('Сбросить пароль').toLowerCase() + '</a>.<br />\n' +
        `<br /><strong>${i18next.t('Для продолжения авторизации, пожалуйста, введите код с картинки и повторите попытку.')}</strong><br />` +
        '				</p>\n' +
        '				<div class="row">\n' +
        '					<div class="col-sm-6 col-sm-offset-1">\n' +
        '						<div class="input-group">\n' +
        '							<span class="input-group-addon" id="reason">' + i18next.t('Код с картинки') + '</span>\n' +
        '							<input type="text" class="form-control" placeholder="' + i18next.t('Код с картинки') + '" aria-describedby="reason" />\n' +
        '						</div>\n' +
        '					</div>\n' +
        '					<div class="col-sm-3">\n' +
        '						<img src="/user/?m=_createAndCheckCaptcha&' + (new Date()).getTime() + '" id="fact" />\n' +
        '					</div>\n' +
        '				</div>\n' +
        '           </div>\n' +
        '           <div class="modal-footer">\n' +
        '               <button type="button" class="btn btn-primary" id="btn_send">\n' +
        '                   <span class="glyphicon glyphicon-log-in" aria-hidden="true"></span> ' + i18next.t('Продолжить') + '\n' +
        '               </button>\n' +
        '           </div>\n' +
        '		</div>\n' +
        '	</div>\n' +
        '</div>\n';

    $('body').append(html_code);

    iamhuman = $('#iamhuman');

    iamhuman
        .modal(
            {
                backdrop: 'static',
                show: true,
                keyboard: false
            }
        )
        .on(
            'shown.bs.modal',
            function () {

                modal_centered(iamhuman);
                $(window).on(
                    'resize.iamhuman',
                    function () {
                        modal_centered(iamhuman);
                    }
                );

                iamhuman.find('#btn_send').on(
                    'click.check_reason',
                    function () {

                        $.post(
                            '/user/?m=_createAndCheckCaptcha',
                            {
                                captcha_value: iamhuman.find('input[aria-describedby=reason]').val()
                            },
                            function (data) {

                                // console.info(data);

                                if (data == 'yes') {
                                    iamhuman.modal('hide');
                                } else {
                                    iamhuman.find('#fact').attr(
                                        'src',
                                        '/user/?m=_createAndCheckCaptcha&' + (new Date()).getTime()
                                    );
                                    iamhuman.find('input[aria-describedby=reason]').val('');
                                }

                            }
                        );

                    }
                );

            }
        )
        .on(
            'hidden.bs.modal',
            function () {
                iamhuman.remove();
            }
        );
}
