import '../scss/components/common.password.component.scss';

import $ from 'jquery';
import 'jquery-migrate';
import 'jquery.cookie';
import 'bootstrap/js/transition';
import 'bootstrap/js/modal';
import 'jquery.noty.packaged.js';

import '../../design/js/login';
import '../../design/js/topRight';
import '../../design/js/default';
import '../../design/js/index';
import PasswordComponent from './components/common/password.component';

$(() => {
    const passwordComponent = new PasswordComponent();
    $('body')
        .on('click.toggle', '.btn-toggle-password-visibility', passwordComponent.handlerClickToggle.bind(passwordComponent))
    ;
});
