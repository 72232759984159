import $ from 'jquery';

export default class CaptchaComponent {
    constructor() {
        this.$body = $('body');
    }

    init() {
        this.$modal = this.$body.find('.modal-captcha-component');
        if (!this.$modal.length) {
            this.$modal = $(this._htmlModal()).appendTo(this.$body);
            this.events();
        }
        this.$imgCaptcha = this.$modal.find('.img-captcha');
        this.$inputKeyString = this.$modal.find('.input-key-string');
    }

    events() {
        this.$body
            .on('show.bs.modal.captcha', '.modal-captcha-component', this.handlerShowBsModalCaptcha.bind(this))
            .on('shown.bs.modal.captcha', '.modal-captcha-component', this.handlerShownBsModalCaptcha.bind(this))
            .on('submit.verify', '.modal-captcha-component form', this.handlerSubmitVerify.bind(this))
            .on('click.verify', '.modal-captcha-component .btn-ok', this.handlerClickVerify.bind(this))
        ;
    }

    handlerShowBsModalCaptcha() {
        const urlSearchParams = new URLSearchParams(this.$imgCaptcha.attr('src'));
        urlSearchParams.set('time', new Date().getTime().toString());
        this.$imgCaptcha.attr('src', decodeURIComponent(urlSearchParams.toString()));
        this.$inputKeyString.val('');
    }

    handlerShownBsModalCaptcha() {
        this.$inputKeyString.focus();
    }

    handlerSubmitVerify(event) {
        event.preventDefault();
        this.$modal.find('.btn-ok').trigger('click.verify');
    }

    handlerClickVerify(event) {
        let $self = $(event.currentTarget);
        $self.prop('disabled', true);
        const urlSearchParams = new URLSearchParams(this.$imgCaptcha.attr('src'));
        $.post('/user/?m=_verifyCaptcha', {
            token_id: urlSearchParams.get('token_id'),
            key_string: this.$inputKeyString.val()
        }, () => {
            this.$modal.one('hidden.bs.modal.callback', () => this._callbackAfterVerify());
            this.$modal.modal('hide');
        }).fail(jqXHR => {
            console.error(jqXHR);
            this.$modal.trigger('show.bs.modal.captcha');
        }).always(() => {
            $self.prop('disabled', false);
        });
    }

    showModal(tokenId, callback) {
        if (!this.$modal) {
            this.init();
        }
        const urlSearchParams = new URLSearchParams(this.$imgCaptcha.attr('src'));
        urlSearchParams.set('token_id', tokenId);
        this.$imgCaptcha.attr('src', decodeURIComponent(urlSearchParams.toString()));
        this._callbackAfterVerify = callback;
        this.$modal.modal('show');
    }

    _htmlModal() {
        return `<div class="modal fade modal-captcha-component" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                <h4 class="modal-title">${i18next.t('Слишком много запросов. Пожалуйста, подтвердите, что Вы не робот.')}</h4>
            </div>
            <div class="modal-body">
                <form class="form-horizontal">
                    <div class="form-group">
                        <div class="col-sm-3">
                            <img src="/user/?m=_createCaptcha&token_id=token_id&time=${new Date().getTime()}"
                                 alt="Captcha" class="img-captcha" loading="lazy"/>
                        </div>
                        <div class="col-sm-9">
                            <input type="text" class="form-control input-key-string" placeholder="${i18next.t('Введите код с картинки')}"
                                   aria-label="${i18next.t('Введите код с картинки')}"/>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default btn-cancel" data-dismiss="modal">
                    <span class="glyphicon glyphicon-remove" aria-hidden="true"></span> ${i18next.t('Отмена')}
                </button>
                <button type="button" class="btn btn-primary btn-ok">
                    <span class="glyphicon glyphicon-ok" aria-hidden="true"></span> ${i18next.t('Ок')}
                </button>
            </div>
        </div>
    </div>
</div>`;
    }

    _callbackAfterVerify() {
    }
}
