$(document).ready(function () {
  /*[изм.8969 н.0000170207] [изм.8969 н.0000170333]*/
  if (location.pathname === '/') {
    if (!$('input[name=s_user_id]').val()) {
      // Удаляем пробел в верху страницы
      $('#top')
        .find('table.topTable>tbody')
        .find('tr:eq(0)')
        .empty();

      // [изм.8969 н.0000166970]
      var welcomeNewBlock  = $('.welcome-new');
      var welcomeNewModal  = $('.welcome-new .welcome-new-modal');
      var modalCarBusiness = $('.welcome-new .modal-car-business');

      // Центрируем блок
      // $(window).on('scroll resize', function () {
        // welcomeNewBlock.css('left', ($(window).width() - welcomeNewBlock.outerWidth()) / 2);
      // });

      // $("body").trigger("scroll");

      welcomeNewBlock
        .on('click.wholesale', '#wholesale', function (e) {
          e.preventDefault();
          welcomeNewModal
            .modal({
              backdrop: 'static',
              keyboard: false,
              show    : true
            });
        });

      welcomeNewModal
        .on('click', '.yes', function () {
          window.location.href = '/user/?m=reg';
        })
        .on('click', '.no', function () {
          welcomeNewModal.modal('hide');
          modalCarBusiness
            .modal({
              backdrop: 'static',
              keyboard: false,
              show    : true
            });
        })
        .on('shown.bs.modal', function () {
          $(this).find('.modal-dialog').css({
            'margin-top': $(window).height() / 2 - $(this).find('.modal-content').height() / 2
          });
        });

      modalCarBusiness
        .on('click', '.yes', function () {
          window.location.href = '/user/?m=reg';
        })
        .on('click', '.no', function () {
            if(i18next.language === 'en') {
                window.location.href = 'https://glass.pavelauto.parts'; /*209397 и.12212*/
            } else {
                window.location.href = 'https://autotrade.su';
            }
        })
        .on('shown.bs.modal', function () {
          $(this).find('.modal-dialog').css({
            'margin-top': $(window).height() / 2 - $(this).find('.modal-content').height() / 2
          });
        });
    }
  }
});